
// Add a google map polyline overlay representing stripe angle to job polygons when editing stripe angle.
// Adapted from https://stackoverflow.com/a/15840086
$(window).on("gmap-script-ready show-stripe-overlay jobs-map-rendered", (event, selectedJob, jobsMap) => {
  var job = null
  // If job edit form is open, use the job being edited, or else use the current job if there is one
  if (selectedJob) {
    job = selectedJob
  } else {
    if (previousJobs.jobToEdit) {
      job = previousJobs.jobToEdit
    } else if (map.currentJob?.jobId) {
      job = map.currentJob
    }
  }
  if (job && !job.isRecordAndRepeat) {
    function initialize() {
      // Use the striple angle from the job if it was manually set, or use angle from plan if it exists
      if (typeof (job.stripeAngle) == 'number') {
        var stripeAngle = job.stripeAngle
      } else if (event.type != 'jobs-map-rendered' && typeof (companion?.plan?.stripeAngle) == 'number') {
        var stripeAngle = companion.plan.stripeAngle
      } else {
        var stripeAngle = 90
      }
      let stroke = (job.stripeAngle ?? companion.plan?.stripeAngle) != null ? "#000" : null
      companion.stripeAngleOverlay?.setMap(null)
      companion.stripeAngleOverlay = new BW.PolyLineFill(job.paths, map.gmap, stripeAngle, stroke)
      companion.toggleStripePlan()
    }

    PolyLineFill.prototype = new google.maps.OverlayView()

    function PolyLineFill(poly, gmap, stripeAngle, stroke) {
      var bounds = new google.maps.LatLngBounds()
      for (var i = 0; i < poly.length; i++) {
        bounds.extend(poly[i])
      }

      this.bounds_ = bounds
      this.map_ = gmap
      this.div_ = null
      this.poly_ = poly
      this.polysvg_ = null
      this.stroke_ = stroke
      this.stripeAngle_ = stripeAngle

      this.setMap(gmap)
    }

    PolyLineFill.prototype.onAdd = function () {
      // Create the DIV and set some basic attributes.
      var div = document.createElement('div')
      div.style.borderStyle = 'none'
      div.style.borderWidth = '0px'
      div.style.position = 'absolute'
      div.id = 'job-edit-overlay'

      // Create the svg element
      var svgns = "http://www.w3.org/2000/svg"
      var svg = document.createElementNS(svgns, "svg")
      svg.setAttributeNS(null, "preserveAspectRatio", "xMidYMid meet")

      var def = document.createElementNS(svgns, "defs")

      // Create the pattern fill
      var pattern = document.createElementNS(svgns, "pattern")
      pattern.setAttributeNS(null, "id", "lineFill")
      pattern.setAttributeNS(null, "patternUnits", "userSpaceOnUse")
      pattern.setAttributeNS(null, "patternTransform", `rotate(${-this.stripeAngle_})`)
      pattern.setAttributeNS(null, "height", "10")
      pattern.setAttributeNS(null, "width", "7")
      def.appendChild(pattern)

      var rect = document.createElementNS(svgns, "rect")
      rect.setAttributeNS(null, "id", "rectFill")
      rect.setAttributeNS(null, "fill-opacity", "0")
      rect.setAttributeNS(null, "stroke", this.stroke_)
      rect.setAttributeNS(null, "stroke-dasharray", "0,0")
      rect.setAttributeNS(null, "height", "1")
      rect.setAttributeNS(null, "width", "7")
      pattern.appendChild(rect)

      svg.appendChild(def)

      // Add path to the div
      var path = document.createElementNS(svgns, 'path')
      path.setAttributeNS(null, 'fill', 'url(#lineFill)')
      path.setAttributeNS(null, 'stroke-width', '0')
      this.path_ = path
      svg.appendChild(this.path_)

      div.appendChild(svg)

      // Set the overlay's div_ property to this DIV
      this.div_ = div
      this.div_.className = 'polygon'

      // Add an overlay to a map via one of the map's panes.
      // Add this overlay to the overlayLayer pane.
      var panes = this.getPanes()
      panes.mapPane.appendChild(div)
    }

    PolyLineFill.prototype.AdjustPoints = function () {
      // Adjust the polygon points based on the projection.
      var proj = this.getProjection()
      var sw = proj.fromLatLngToDivPixel(this.bounds_.getSouthWest())
      var ne = proj.fromLatLngToDivPixel(this.bounds_.getNorthEast())

      var points = ""
      for (var i = 0; i < this.poly_.length; i++) {
        var point = proj.fromLatLngToDivPixel(this.poly_[i])
        if (i == 0) {
          points += (point.x - sw.x) + ", " + (point.y - ne.y)
        } else {
          points += " " + (point.x - sw.x) + ", " + (point.y - ne.y)
        }
      }
      return points
    }

    PolyLineFill.prototype.draw = function () {
      // Size and position the overlay. We use a southwest and northeast position of the overlay to peg it to the correct position and size.
      // We need to retrieve the projection from this overlay to do this.
      var overlayProjection = this.getProjection()

      // Retrieve the southwest and northeast coordinates of this overlay in latlngs and convert them to pixels coordinates.
      // Use these coordinates to resize the DIV.
      var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest())
      var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast())

      // Resize the image's DIV to fit the indicated dimensions.
      var div = this.div_
      div.style.left = sw.x + 'px'
      div.style.top = ne.y + 'px'
      div.style.width = (ne.x - sw.x) + 'px'
      div.style.height = (sw.y - ne.y) + 'px'

      this.path_.setAttributeNS(null, "d", 'M' + this.AdjustPoints() + 'z')
    }

    PolyLineFill.prototype.onRemove = function () {
      this.div_.parentNode.removeChild(this.div_)
      this.div_ = null
    }
    window.BW = {}
    window.BW.PolyLineFill = PolyLineFill
    google.maps.event.addDomListener(window, 'load', initialize)
    if (event.type == 'show-stripe-overlay' || event.type == 'jobs-map-rendered') { initialize() }
  }
})
